@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* add font */

@font-face {
  font-family: TatsamBook;
  src: url("./fonts/TatsamBengaliRnd-Book.otf") format("opentype");
}
@font-face {
  font-family: TatsamBold;
  src: url("./fonts/TatsamBengaliRnd-Bold.otf") format("opentype");
}

@layer base {
  html[lang="en"] {
    @apply font-sans;
  }
  html[lang="bn"] {
    @apply font-TatsamBook;
  }
}

@layer components {
  .btn_start {
    @apply absolute sm:mt-3 px-3 sm:px-5 py-3 overflow-hidden  text-sm lg:text-xl font-bold bg-white text-purple-600 rounded-lg shadow-inner;
  }

  .box-shadow {
    box-shadow: 0 4px 8px 0 rgba(47, 48, 67, 0.02),
      0 6px 20px 0 rgba(47, 48, 67, 0.4);
  }

  .select-wrapper {
    position: relative;
/*     
    &::after {
      content: url("data:image/svg+xml,%3Csvg%20fill%3D%22none%22%20viewBox%3D%220%200%2012%207%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20stroke%3D%22%235E6A7A%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%221.6%22%20d%3D%22M1%20.808l5%205%205-5%22%2F%3E%3C%2Fsvg%3E");
      width: 12px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      transition: all .3s ease;
    } */
  }
}

@layer base {
  /* select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    position: relative;
} */
}

#domEl {
  position: relative;
  width: 800px;
}
.locale-wrapper {
  display: flex;
  justify-content: end;
}
@media screen and (max-width: 1366px) {
  #domEl {
    width: 800px;
  }
}
@media screen and (max-width: 768px) {
  #domEl {
    width: 100%;
  }
}

@media screen and (max-width: 380px) {
  #domEl {
    h2 {
      max-width: 100px;
    }
  }
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
}

.spinner .path {
  fill: none;
  stroke: hsl(210, 70%, 75%);
  stroke-width: 6;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}